
import { defineComponent } from "vue";
import BaseTable from '@/components/ui/BaseTable.vue'
import BaseIcon from '@/components/ui/BaseIcon.vue';

interface Product {
  articleId: number;
  article: string;
  name: string;
  productGroup: string;
  supplier: string;
  price: number;
  discountPrice: number;
  percent: number;
  standard: boolean;
  oeNumber: string;
  applic: string | null;
  deviation: number;
  discountPriceByCompleteness: number;
}

export default defineComponent({
  name: 'DetailPricesTableByArticle',
  props: {
    currentSupplierAndBrands: {
      type: Object,
      required: true,
      default: () => null
    },
    contentItems: {
      type: Array,
      required: false,
      default: () => null
    },
  },
  components: {
    BaseTable,
    BaseIcon
  },
  data: () => ({
    activeSort: 'desc'
  }),
  computed: {
    headerItems(): any {
      return [
        {
          title: 'Бренд',
          content: (product: Product) =>  product.supplier
        },
        {
          title: 'Артикул',
          content: (product: Product) => product.article
        },
        {
          title: 'Цена',
          content: (product: Product) => `${Math.round(product.discountPrice)}₽`
        },
      ]
    },
  },
  methods: {
    sortData(products: Product[]) {
      return products.sort((a, b) => {
        if (a.supplier === 'FENOX' && b.supplier !== 'FENOX') {
          return -1;
        }
        if (a.supplier !== 'FENOX' && b.supplier === 'FENOX') {
          return 1;
        }
        return this.activeSort === 'asc' ? a.price - b.price : b.price - a.price;
      });
    },
    setSort() {
      this.activeSort = this.activeSort === 'asc' ? 'desc' : 'asc';
    }
  }
})
