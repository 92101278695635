
import {defineComponent} from "vue";
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import routerService from "@/services/routerService";
import BaseTable from '@/components/ui/BaseTable.vue'
import TableProductGroupItem from "./TableProductGroupItem.vue";
import DetailPricesTableByArticle from "./DetailPricesTableByArticle.vue";
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';

export default defineComponent({
  name: 'DetailPricesComparisonTableWrapper',
  props: {
    currentSupplierAndBrands: {
      type: Object,
      required: true,
      default: () => null
    },
    articlesForDetailTable: {
      type: Array,
      required: false,
      default: () => null
    },
    selectedBrands: {
      type: Array,
      required: true,
      default: () => null
    },
  },
  components: {
    BaseCheckbox,
    BaseTable,
    TableProductGroupItem,
    DropdownEllipsis,
    DetailPricesTableByArticle
  },
  data: () => ({
    articlesForTable: null as any,
  }),
  computed: {
    productGroupForTable() {
      const uniqProductGroupsNames = Array.from(
        new Set(
          this.articlesForDetailTable
            .flat(1)
            .map((article: any) => article.productGroup)
        )
      ) as any

      const transormedDataForTableByProductGroup = uniqProductGroupsNames.map((productGroupName: string) => {
        let countArrWithProductGroups = 0 // кол-во массивов с текущей товарной группой
        let sumDeviationProductGroupsAndBrand = {} as any // сумма отклонения всех артикулов по товарной группе по определнному бренду
        let allArticlesProductGroup = [] as any // все массивы артикулов с данной товарной группой
        const calcSumDeviationProductGroupsAndBrand = {} as any

        this.articlesForTable.map((arrArticles: any) => {
          if (arrArticles[0].productGroup === productGroupName) { //если первый элемент в массиве совпал с ТГ из уникальных ТГ
            countArrWithProductGroups += 1

            allArticlesProductGroup.push(arrArticles) // добавляем весь массив с артикулами для эталона и других брендов

            const arrSupplierAndSelectedBrands = [
              ...this.currentSupplierAndBrands?.brands,
              this.currentSupplierAndBrands?.supplier
            ]

            arrSupplierAndSelectedBrands.map((brand: any) => { // высчитываем для каждого бренда процент оклонения по товарной группе
              arrArticles.map((article: any) => {
                if (article.supplier === brand.name) {
                  if (typeof article.deviation === 'string') { // проверяем или в отклонения записана строка с несколькими значениями
                    const arrDeviations = article.deviation.split(';').sort((a: number, b: number) => b - a) // строку с отклонения делаем в массив и сортируем от меньшего к большему

                    const middleArrDeviations = arrDeviations.length / 2; // средина массива
                    const deviationMediane = Number(arrDeviations[middleArrDeviations]) // медиана

                    if (sumDeviationProductGroupsAndBrand[brand.name]) {
                      sumDeviationProductGroupsAndBrand[brand.name] += deviationMediane
                    } else {
                      sumDeviationProductGroupsAndBrand[brand.name] = deviationMediane
                    }
                    return;
                  }

                  if (sumDeviationProductGroupsAndBrand[brand.name]) {
                    sumDeviationProductGroupsAndBrand[brand.name] += article.deviation
                  } else {
                    sumDeviationProductGroupsAndBrand[brand.name] = article.deviation
                  }
                }
              })
            })
          }
        })

        const deviationsProductGroups = {} as any

        allArticlesProductGroup.map((arrArticles: any) => {
          arrArticles.map(({supplier, deviation}: any) => {
            if (typeof deviation === 'string') {
              if (deviationsProductGroups[supplier]) {
                deviationsProductGroups[supplier] = [
                  ...deviation.split(';').map((string: any) => +string),
                  ...deviationsProductGroups[supplier]
                ]
              } else {
                deviationsProductGroups[supplier] = [
                  ...deviation.split(';').map((string: any) => +string)
                ]
              }
            } else {
              if (deviationsProductGroups[supplier]) {
                deviationsProductGroups[supplier] = [
                  deviation,
                  ...deviationsProductGroups[supplier]
                ]
              } else {
                deviationsProductGroups[supplier] = [deviation]
              }
            }
          })
        })

        const resultDeviations = {} as any

        Object
          .keys(deviationsProductGroups)
          .map((supplier: string) => {
            const deviationsByBrand = deviationsProductGroups[supplier]


            const median = (arr: Array<number>) => {
              const mid = Math.floor(arr.length / 2)
              const nums = [...arr].sort((a, b) => a - b)

              return arr.length % 2 !== 0
                ? nums[mid]
                : (nums[mid - 1] + nums[mid]) / 2;
            };

            resultDeviations[supplier] = median(deviationsByBrand)
          })

        return {
          deviation: resultDeviations,
          productGroup: productGroupName,
          articles: allArticlesProductGroup,
        }
      })

      return transormedDataForTableByProductGroup
    },
    headerArticles(): any {
      return [
        {
          title: `Артикулы ${this.currentSupplierAndBrands.supplier.name}`,
          content: (comparisonArticle: any) => comparisonArticle[0].article
        },
        ...this.currentSupplierAndBrands.brands.map((brand: any, indexBrandName: number) => ({
          title: brand.name,
          content: (comparisonArticle: any) => {
            const indexComparisonArticleBySupplier = comparisonArticle.findIndex((article: any) => article.supplier === brand.name)
            return comparisonArticle[indexComparisonArticleBySupplier]?.other ?? '-'
          }
        }))
      ]
    },
    headerProductGroup(): any {
      return [
        {
          title: `Товарная группа ${this.currentSupplierAndBrands.supplier.name}`,
          content: (transformedDataProductGroup: any) => transformedDataProductGroup.productGroup,
        },
        ...this.currentSupplierAndBrands.brands.map((brand: any, indexBrandName: number) => ({
          title: brand.name,
          content: (transformedDataProductGroup: any) => {
            return transformedDataProductGroup && transformedDataProductGroup.deviation[brand.name]
                    ? Math.round(transformedDataProductGroup.deviation[brand.name]) + '%'
                    : '-'
          }
        }))
      ]
    }
  },
  methods: {
    setHistory(articles: any) {
      const foundArticleSplice = articles.find((article: any) => article && article.spliceArticle)
      const articlesForHistory = foundArticleSplice
        ? [...articles, ...foundArticleSplice.spliceArticle]
        : articles

      this.$emit('setHistory', articlesForHistory)
    },
    setViewTable(is: any) {
      routerService.setQuery('showTable', is, this.$route.query, this.$router)
    },
    processItems(items: any[], brandName: string) {
      const itemWithSameBrandExists = items.some(item => item.supplier === brandName);

      if (itemWithSameBrandExists) {
        return this.mergeItemsWithSameBrand(items, brandName);
      } else {
        return {
          supplier: brandName,
          other: '-',
          deviation: 0
        };
      }
    },
    mergeItemsWithSameBrand(items: any[], brandName: string) {
      let currentItem = null;

      for (let index = 0; index < items.length; index++) {
        if (items[index].supplier === brandName) {
          if (!currentItem) {
            currentItem = { ...items[index] };
            // Ensure the initial prices have the ruble sign
            currentItem.price = currentItem.price ? `${Math.round(currentItem.price)}` : 'Нет цены';
            currentItem.discountPrice = currentItem.discountPrice ? `${Math.round(currentItem.discountPrice)}` : 'Нет цены';
            currentItem.discountPriceByCompleteness = currentItem.discountPriceByCompleteness ? `${Math.round(currentItem.discountPriceByCompleteness)}` : 'Нет цены';
          } else {
            currentItem.article += `;${items[index].article}`;
            currentItem.deviation = this.formatDeviation(currentItem.deviation, items[index].deviation);

            const currentPrice = items[index].price ? `${Math.round(items[index].price)}` : 'Нет цены';
            currentItem.price += `;${currentPrice}`;

            const currentDiscountPrice = items[index].discountPrice ? `${Math.round(items[index].discountPrice)}` : 'Нет цены';
            currentItem.discountPrice += `;${currentDiscountPrice}`;

            const currentDiscountPriceByCompleteness = items[index].discountPriceByCompleteness ? `${Math.round(items[index].discountPriceByCompleteness)}` : 'Нет цены';
            currentItem.discountPriceByCompleteness += `;${currentDiscountPriceByCompleteness}`;

            items.splice(index, 1);
            index--; // Adjust index after removal
          }
        }
      }

      return currentItem || { other: '-' };
    },
    formatDeviation(currentDeviation: any, nextDeviation: any) {
      const currentDeviationFormatted = typeof currentDeviation === 'number'
        ? currentDeviation.toFixed(1)
        : currentDeviation;

      const nextDeviationFormatted = nextDeviation.toFixed(1);

      return `${currentDeviationFormatted}, ${nextDeviationFormatted}`;
    },
    mapResultItem(item: any) {
      const formatPrice = (value: any) => {
        if (typeof value === 'number') {
          return value > 0 ? `${Math.round(value)}₽` : 'Нет цены';
        }
        return value || 'Нет цены';
      };

      return {
        ...item,
        article: item.article || '-',
        price: formatPrice(item.price),
        discountPrice: formatPrice(item.discountPrice),
        discountPriceByCompleteness: formatPrice(item.discountPriceByCompleteness),
        other: item.article
      }
    }
  },
  watch: {
    articlesForDetailTable: {
      handler() {
        this.setViewTable('articles');
        if (!this.articlesForDetailTable) return [];
        
        this.articlesForTable = this.articlesForDetailTable.map((items: any) => {
          const result = this.selectedBrands.map((brand: any) => {
            return this.processItems(items, brand.name);
          });

          return result.map(this.mapResultItem);
        });
      },
      immediate: true,
    }
  }
})
